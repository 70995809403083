.loadingArea {
  padding-top: 10vh;
}

.lottiePlay {
  width: 40vw;
  height: 33vh;
}

.retryButton {
  margin-top: 32px;
}

.productDescription {
  margin: 16px 0 32px;
}

.appInfoRow {
  padding-bottom: 16px;
}

.appInfoRowHeading {
  margin-bottom: 8px;
}

.inputAddon {
  cursor: pointer;
}

.fixedWidthLoader {
  width: 30px;
}
