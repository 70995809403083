.rightHeaderItem {
  margin-left: 16px;
}

.header {
  height: 60px;
  padding: 0 16px;
}

.logo {
  font-size: 36px;
  color: #222;

  &.white {
    color: white;
  }
}
