.content, .container {
  width: 100%;
  height: 100%;
}

.container {
  align-content: stretch;
  justify-content: stretch;
}

.footer {
  height: 80px;
}
