.mainContainerStyles {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageContainerWithPlaceholderStyles {
  width: 40vw;
  height: 33vh;
}

.imageContainerStyles {
  margin-bottom: 24px;
}

.headingContainerWithPlaceholderStyles {
  width: 40vw;
}

.headingContainerStyles {
  margin-bottom: 24px;
}

.paragraphContainerWithPlaceholderStyles {
  width: 40vw;
}

.paragraphContainerStyles {
  font-size: 18px;
}
