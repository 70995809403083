.detailsButton {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.lottiePlay {
  width: 40vw;
  height: 33vh;
}

.retryButton {
  margin-top: 32px;
}


.loadingArea {
  padding-top: 10vh;
}
