.wrapper {
  width: 100%;
  height: 100%;
}

.container {
  width: 360px;
}

.centerizeFirst {
  display: flex;
  justify-content: center;
}

.centerizeLast {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.or {
  display: flex;
  justify-content: center;
  margin: 12px 0 8px;
  font-size: 12px;
}
