.iconStyles {
  width: 56px;
  height: 56px;
  line-height: 56px;
  text-align: center;
}

.headerStyles {
  padding: 18px;
  font-size: 16px;
  height: 56px;
  background: #34c3ff;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
}

.content, .container {
  width: 100%;
  height: 100%;
}

.container {
  align-content: stretch;
  justify-content: stretch;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #EEEEEE;
}

.sidebarBottomArea {
  background: #EEEEEE !important;
}

.contentArea {
  padding: 16px;
}

[data-theme="dark"] {
  .sidebar, .sidebarBottomArea {
    background: #151616 !important;
  }
}
